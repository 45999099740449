import { useMemo } from "react";
import { TOOL_ITEMS } from "domains/tools/constants";
import { useUserContext } from "domains/user/contexts/UserProvider";

export function useToolItems() {
  const { featureFlags } = useUserContext();
  return useMemo(
    () =>
      TOOL_ITEMS.map((tool) => {
        if (tool.featureFlag && !featureFlags.includes(tool.featureFlag)) {
          return {
            ...tool,
            internalLink: undefined,
            soon: true,
          };
        }
        return tool;
      }),
    [featureFlags]
  );
}
